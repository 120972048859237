import React from 'react';
import axios from 'axios';
import moment from 'moment'

import { useNavigate } from 'react-router-dom';
import { CustomPanel } from './common/CustomPanel';

import { checkIfLoggedIn } from '../services/utilService';
import CampLayout from './common/CampLayout'

const Orders = () => {
    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    }
    const navigate = useNavigate();

    const [orders, setOrders] = React.useState([]);

    React.useEffect(() => {
        checkIfLoggedIn();

        if (!localStorage.getItem("user-id")) {
            navigate("/login");
        } else {
            let mounted = true;
            axios.get("/users/" + localStorage.getItem("user-id") + "/orders")
                .then((response) => {
                    if (mounted) {
                        setOrders(response.data
                            .sort((a, b) => a.createdDate < b.createdDate ? 1 : -1)
                        );
                    }
                });
            return () => mounted = false;
        }
    }, []);

    return (
        <CampLayout page="orders" cart={JSON.parse(localStorage.getItem("cart"))}>
            <div className="px-7 pb-3">
                <p className="text-lg mb-3"><b>Your requests</b></p>
                {orders.map((order) =>
                    <div key={order._id} className="mb-0 lg:mb-3">
                        <div className="hidden lg:block"> 
                            <div className={classNames(order.items.some(({ week }) => (moment().isSame(week.endDate, 'day') || moment().diff(week.endDate) > 0)) ? "text-gray-400" : "")}>
                                <b>{moment(order.createdDate).format("DD/MM/YYYY")} (Registration: {order.orderNumber})</b><br></br>
                            </div>
                        </div>
                        
                        <div className="shadow mt-1 overflow-auto border border-gray-200 w-full rounded-lg hidden lg:block">
                            <table className="table-fixed w-full">
                                <thead>
                                    <tr className="border-b">
                                        <th className="w-2/12 text-left py-3 pl-5">Kid</th>
                                        <th className="w-2/12 text-left py-3">Camp</th>
                                        <th className="w-3/12 text-left">Location</th>
                                        <th className="w-2/12 text-left">Dates</th>
                                        <th className="w-1/12 text-left">Option</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {order.items.map(item =>
                                        <tr key={item.week._id + item.kid._id}>
                                            <td className="py-5 pl-5">{item.kid.firstName + " " + item.kid.lastName}</td>
                                            <td>{item.week.theme ? item.week.theme + ' (' + item.week.camp.name + ')' : item.week.camp.name}</td>
                                            <td>{item.week.location.name} ({item.week.location.address})</td>
                                            <td>{moment(item.week.startDate).format("DD/MM/YYYY")} - {moment(item.week.endDate).format("DD/MM/YYYY")}</td>
                                            <td>{item.dayOption}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="overflow-auto w-full block lg:hidden order-panel">
                            <CustomPanel
                                key={order._id}
                                header={`${moment(order.createdDate).format("DD/MM/YYYY")} (Registration: ${order.orderNumber})`}
                                collapsible
                                bordered
                                expanded={order.items.some(({ week }) => moment().diff(week.endDate) <= 0)}
                            >
                                {order.items.map(item =>
                                    <ul key={item.week._id + item.kid._id} className="shadow border border-gray-200 w-full rounded-lg p-3 relative">
                                        <li className="flex flex-row">
                                            <div className='w-20 font-bold inline-block pb-1'>Kid</div>
                                            <div className='custom-overflow-ellipsis'>{item.kid.firstName + " " + item.kid.lastName}</div>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className='w-20 font-bold inline-block pb-1'>Camp</div>
                                            <div className='custom-overflow-ellipsis'>{item.week.theme ? item.week.theme + ' (' + item.week.camp.name + ')' : item.week.camp.name}</div>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className='w-20 font-bold inline-block pb-1'>Location</div>
                                            <div className='custom-overflow-ellipsis'>{item.week.location.name} ({item.week.location.address})</div>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className='w-20 font-bold inline-block pb-1'>Dates</div>
                                            <div className='custom-overflow-ellipsis'>{moment(item.week.startDate).format("DD/MM/YYYY")} - {moment(item.week.endDate).format("DD/MM/YYYY")}</div>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className='w-20 font-bold inline-block pb-1'>Option</div>
                                            <div className='custom-overflow-ellipsis'>{item.dayOption}</div>
                                        </li>
                                    </ul>
                                )}
                            </CustomPanel>
                        </div>
                    </div>
                )}
            </div>
        </CampLayout>
    )
}

export default Orders;
