import React from 'react';
import { Container, Header, Sidebar, Content, Footer } from 'rsuite';
import CampCart from './CampCart';
import CampSidebar from './CampSidebar';
import CampTopbar from './CampTopbar';

const CampLayout = ({ children, page, cart, step, setStep }) => {
    const [isMenu, setIsMenu] = React.useState(false);
    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
      }
    return (
        <Container style={{ height: '100vh' }}>
            <Header>
                <CampTopbar cart={cart} setIsMenu={setIsMenu}/>
            </Header>
            <Container>
                <Sidebar
                    className={classNames(
                        'lg:w-260 lg:block',
                        isMenu ? 'block fixed top-0 bottom-0 h-full z-10 bg-white' : 'hidden',
                    )}
                    style={{ borderRight: '1px solid #DCDCDC' }}
                    >
                    <CampSidebar isMenu={isMenu} setIsMenu={setIsMenu} page={page} />
                </Sidebar>
                <Content style={{ paddingTop: 30 }}>{children}</Content>
                <Sidebar style={{ borderLeft: '1px solid #DCDCDC' }} width={page === 'book' && step === 1 && cart.length > 0 && window.innerWidth > 1200 ? 200 : 0} hidden={page !== 'book' || step !== 1 || cart.length === 0 || window.innerWidth <= 1200}>
                    <CampCart cart={cart} setStep={setStep}/>
                </Sidebar>
            </Container>
            <Footer></Footer>
        </Container>
    )
}

export default CampLayout;
