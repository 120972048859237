import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, ButtonToolbar } from 'rsuite';
import { Field } from './common/Field'
import { toast } from 'react-toastify';
import { Validator } from "@rsuite/validation";

const model = Validator.SchemaModel({
    email: "required|email"
});

const ForgotPassword = () => {
    const [formValue, setFormValue] = React.useState({ email: '' });

    const navigate = useNavigate();

    const formRef = React.useRef();

    const reset = () => {
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        const user = { email: formValue.email };
        axios.post("/users/forgotPassword", user)
            .then(() => {
                toast.success('Please check your mailbox for instructions');
            })
            .catch((error) => {
                toast.error(error.response.data.msg, { position: "top-center" });
            });
    }


    return (

        <div className="flex w-full min-h-screen place-items-center justify-center bg-blue-tech-hexagon bg-cover">
            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                model={model}
                className="rounded-lg shadow-2xl border bg-white p-5 w-full max-w-342 lg:w-342"
            >

                <Field
                    name="email"
                    label="Email"
                />


                <ButtonToolbar>
                    <Button appearance="primary" onClick={reset} block>
                        Reset Password
                    </Button>
                </ButtonToolbar>

            </Form>
        </div>
    )
}

export default ForgotPassword;