import axios from 'axios';

export const checkIfLoggedIn = () => {
    console.log('auth-token: ' + localStorage.getItem("auth-token"));
    console.log('user-id: ' + localStorage.getItem("user-id"));

    let token = localStorage.getItem("auth-token");
    if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
    }
    return axios.post('/users/isTokenValid', null, { headers: { "x-auth-token": localStorage.getItem("auth-token") } })
        .then((tokenResponse) => {
            if (tokenResponse.data) {
                axios.get("/users", { headers: { "x-auth-token": token } })
                    .then((userResponse) => {
                        localStorage.setItem("user-id", userResponse.data._id);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const searchAddressByPostalcode = async (fv, setFormValue) => {
    await axios.get("/postalCodes/" + fv.postalCode)
        .then((response) => {
            fv.address = response.data.address;
            setFormValue(fv);
        })
        .catch((err) => {
            console.log(err);
            setFormValue(fv);
        });

}