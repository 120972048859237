import React from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, ButtonToolbar } from 'rsuite';
import { Field } from './common/Field'
import { toast } from 'react-toastify';
import { Validator } from "@rsuite/validation";

const model = Validator.SchemaModel({
  password: "required|min:6|regex:[A-Z]|regex:[a-z]|regex:[0-9]",
}, {
  "password.regex": "Should contain at least one number and have a mixture of uppercase and lowercase letters"
});

const ResetPassword = () => {
    const [formValue, setFormValue] = React.useState({ password: '' });
    const search = useLocation().search;

    const navigate = useNavigate();

    const formRef = React.useRef();

    const resetPassword = () => {
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        const password = { password: formValue.password };
        axios.post(`/users/resetPassword?token=${new URLSearchParams(search).get('token')}&id=${new URLSearchParams(search).get('id')}`, password)
            .then(() => {
                toast.success('Password has been updated successfully');
                navigate("/");
            })
            .catch(() => {
                toast.error('Something went wrong. Please try again later.', { position: "top-center" });
            });
    }


    return (

        <div className="flex w-full min-h-screen place-items-center justify-center bg-blue-tech-hexagon bg-cover">
            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                model={model}
                className="rounded-lg shadow-2xl border bg-white p-5 w-full max-w-342 lg:w-342"
                size="full"
            >

                <Field
                    name="password"
                    label="New password"
                    type='password'
                >
                </Field>

                <ButtonToolbar>
                    <Button appearance="primary" onClick={resetPassword} block>
                        Update Password
                    </Button>
                </ButtonToolbar>

            </Form>
        </div>
    )
}

export default ResetPassword;