import axios from "axios";
import React from 'react';
import { BsPlusCircle } from 'react-icons/bs'
import { BiMale, BiFemale } from 'react-icons/bi'
import CampLayout from './common/CampLayout';
import KidInfo from "./KidInfo";
import { useNavigate } from 'react-router-dom';
import { checkIfLoggedIn } from '../services/utilService';
import { toast } from 'react-toastify';

const ManageKids = () => {
    const [kids, setKids] = React.useState([]);
    const [kid, setKid] = React.useState(null);
    const [createKid, setCreateKid] = React.useState(false);

    function removeKid() {
        axios.delete("/kids/" + kid._id);

        setKids(kids.filter((item) => item._id !== kid._id));
        setKid(null);
    }

    function createOrUpdateKid(kidId, kidData) {
        if (kidId) {
            axios.put("/kids/" + kidId, kidData)
                .then((response) => {
                    setKids([...kids.filter((item) => item._id !== response.data._id), response.data]
                        .sort((a, b) => a.createdDate > b.createdDate ? 1 : -1));
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            axios.post("/kids", kidData)
                .then((response) => {
                    if (kids.length === 0) toast.success('Great! You can now book your camp.', {});
                    setKids([...kids, response.data]);
                })
                .catch((error) => {
                    console.log(error);
                })
        };
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const navigate = useNavigate();

    React.useEffect(() => {
        checkIfLoggedIn();

        if (!localStorage.getItem("user-id")) {
            navigate("/login");
        } else {
            let mounted = true;
            axios.get("/users/" + localStorage.getItem("user-id") + "/kids")
                .then((response) => {
                    if (mounted) {
                        setKids(response.data
                            .sort((a, b) => a.createdDate > b.createdDate ? 1 : -1)
                        );
                    }
                });
            return () => mounted = false;
        }
    }, []);

    return (
        <CampLayout page="manage" cart={JSON.parse(localStorage.getItem("cart"))}>
            <div>
                {kids.length === 0 ?
                    <p className="text-base ml-7">This is the place where you can manage your kids. Please add at least one child to be able to book a camp.</p>
                    :
                    <p className="text-base ml-7">Ready to check our awesome camps? <a href="/book" className="underline text-blue-600 hover:text-blue-800">Book your camps</a></p>
                }

                <div className="flex flex-wrap p-7 gap-6 w-full max-w-full">
                    {kids.map((kid) =>
                        <div className="cursor-pointer w-full h-52 rounded-xl border-gray-200 border-1 hover:bg-blue-200 shadow p-5 lg:w-96" onClick={() => setKid(kid)} key={kid._id}>
                            <div className="grid grid-cols-6 grid-flow-row w-full h-full">
                                <div className="grid justify-center place-content-center row-span-full">
                                    {kid.gender === "M" ? <BiMale size={50} color="blue" /> : <BiFemale size={50} color="purple" />}
                                </div>
                                <div className="col-start-2 col-span-4 flex flex-col justify-center gap-2">
                                    <span className="text-xl font-bold text-center">{kid.firstName} {kid.lastName}</span>
                                    <span className="text-base text-center">{getAge(kid.dob)} years old</span>
                                </div>
                                <div className="col-span-6 grid place-content-end justify-center">
                                    <span className="text-xs italic">Click to modify/delete</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="cursor-pointer w-full h-52 rounded-xl border-gray-200 border-1 shadow hover:bg-blue-200 lg:w-96" onClick={() => setCreateKid(true)}>
                        <div className="flex flex-wrap content-center justify-center w-full h-full">
                            <BsPlusCircle size={50} />
                        </div>
                    </div>
                </div>
            </div>

            {kid && <KidInfo kid={kid} setKid={setKid} removeKid={removeKid} createOrUpdateKid={createOrUpdateKid} />}
            {createKid && <KidInfo createKid={createKid} setCreateKid={setCreateKid} createOrUpdateKid={createOrUpdateKid} />}
        </CampLayout>

    )
}

export default ManageKids;
