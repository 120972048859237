import React from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, ButtonToolbar } from 'rsuite';
import { Field } from './common/Field'
import { toast } from 'react-toastify';
import { Validator } from "@rsuite/validation";

const model = Validator.SchemaModel({
    email: "required|email",
    password: "required|min:6",
});

const Login = () => {
    const [formValue, setFormValue] = React.useState({ email: '', password: '' });

    const navigate = useNavigate();

    const formRef = React.useRef();

    const login = () => {
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        const loginUser = { email: formValue.email, password: formValue.password };
        axios.post("/users/login", loginUser)
            .then((loginResponse) => {
                localStorage.setItem("auth-token", loginResponse.data.token);
                localStorage.setItem("user-id", loginResponse.data.user.id);
                navigate("/");
                window.scrollTo(0, 0);
            })
            .catch(() => {
                toast.error('Invalid credentials', { position: "top-center" });
            });
    }


    return (

        <div className="flex w-full min-h-screen place-items-center justify-center bg-blue-tech-hexagon bg-cover">
            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                model={model}
                className="rounded-lg shadow-2xl border bg-white p-5 w-full max-w-342 lg:w-342"
                size="full"
            >

                <Field
                    name="email"
                    label="Email"
                />

                <Field
                    name="password"
                    label="Password"
                    type='password'
                >
                </Field>

                <ButtonToolbar>
                    <Button appearance="primary" onClick={login} block>
                        Log In
                    </Button>
                    <Link to="../forgotPassword" 
                    className="mt-3 block w-full no-underline text-blue-500 text-sm text-center"
                    >Forgot password?</Link>
                    <Link to="../register"
                     className="block w-full no-underline text-blue-500 text-sm text-center"
                     >Sign Up</Link>
                </ButtonToolbar>

            </Form>
        </div>
    )
}

export default Login;