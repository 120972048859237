import axios from 'axios';
import moment from 'moment'
import React from 'react';
import { useLocation } from "react-router-dom";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { MdAddShoppingCart, MdShoppingCart } from 'react-icons/md'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { checkIfLoggedIn } from '../services/utilService';
import { Button, FlexboxGrid, CheckboxGroup, Checkbox, Input, IconButton, Panel } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';

import CampLayout from './common/CampLayout';

const Book = () => {

    const [kids, setKids] = React.useState([]);
    const [camps, setCamps] = React.useState([]);
    const [weeks, setWeeks] = React.useState([]);
    const [allWeeks, setAllWeeks] = React.useState([]);
    const [locations, setLocations] = React.useState([]);

    const location = useLocation();

    const [step, setStep] = React.useState(location.state?.step || 1);
    const [selectedKids, setSelectedKids] = React.useState([]);
    // const [kidId, setKidId] = React.useState(null);
    const [locationId, setLocationId] = React.useState(null);

    const [order, setOrder] = React.useState(null);

    const [items, setItems] = React.useState(JSON.parse(localStorage.getItem("cart")) || []);

    const [comments, setComments] = React.useState("");

    const navigate = useNavigate();

    React.useEffect(() => {
        if (location.state?.step) {
            setStep(location.state?.step);
        }
    },[location]);
    
    React.useEffect(() => {
        checkIfLoggedIn().then(() => {
            if (!localStorage.getItem("user-id")) {
                navigate("/login");
            } else {
                let mounted = true;
                axios.get("/users/" + localStorage.getItem("user-id") + "/kids")
                    .then((response) => {
                        if (mounted) {
                            // setKids(response.data.map(k => ({ value: k._id, label: k.firstName + " " + k.lastName })));
                            setKids(response.data);
                            if (response.data.length > 0) {
                                setSelectedKids([response.data[0]._id]);
                                // setKidId(response.data[0]._id);
                            }
                        }
                    });
                axios.get("/weeks")
                    .then((response) => {
                        if (mounted) {
                            setAllWeeks(response.data);

                            const uniqueCampIds = [];
                            const uniqueCamps = [];
                            response.data.map(week => {
                                week.camp.theme = week.theme;
                                if (uniqueCampIds.indexOf(week.camp._id + "-" + week.camp.theme) === -1) {
                                    uniqueCampIds.push(week.camp._id + "-" + week.camp.theme);
                                    uniqueCamps.push(week.camp);
                                }
                                return null;
                            });
                            setCamps(uniqueCamps.sort((a, b) => a.priority > b.priority ? 1 : -1));
                        }
                    });
                return () => mounted = false;
            }
        }
        );

    }, []);

    function updateWeeks(uuids) {
        setLocationId(null);

        if (!uuids) return;

        setWeeks(allWeeks.filter(w => w.camp._id + "-" + w.theme === uuids[0])
            .sort((a, b) => a.startDate > b.startDate ? 1 : -1)
        );

        const uniqueLocationIds = [];
        const uniqueLocations = [];
        allWeeks.filter(w => w.camp._id + "-" + w.theme === uuids[0]).map(week => {
            if (uniqueLocationIds.indexOf(week.location._id) === -1) {
                uniqueLocationIds.push(week.location._id);
                uniqueLocations.push(week.location);
            }
            return null;
        });
        setLocations(uniqueLocations);
    }

    function setKidsForBooking(e) {
        setSelectedKids(e);
        // if (e.length !== 0) {
        //     setSelectedKids(e);
        // } else {
        //     toast.error('At least one kid should be selected', {});
        // }
    }

    function changeDayOption(event, week) {
        week.dayOption = event.target.value;
    }

    function addToCart(week) {
        let duplicatedWeeks = selectedKids.map(k =>
            items.filter((i) => i.week.startDate === week.startDate && i.kid._id === k)
        ).reduce((a, b) => a + b, '');

        if (duplicatedWeeks.length > 0) {
            toast.error('Looks like you booked a camp for this week already. Please check your cart.', {});
            return;
        } 

        if (!week.dayOption) week.dayOption = 'Full day'; // todo avoid hard-coding
        let option = week.camp.options.find(option => {
            return option.name === week.dayOption;
        });

        let newItems = items;
        selectedKids.map(k => newItems = [...newItems, {
            "week": week,
            "dayOption": week.dayOption,
            "price": option.price,
            "kid": kids.filter(kk => kk._id === k)[0]
        }]);

        // console.log("newItems= " + JSON.stringify(newItems))

        setItems(newItems);
        localStorage.setItem("cart", JSON.stringify(newItems));

        toast.success('Added to the cart', {});
    }

    function deleteFromCart(weekId, kidId) {
        const newItems = items.filter((item) => (item.week._id !== weekId || (kidId && item.kid._id !== kidId)));
        setItems(newItems);
        localStorage.setItem("cart", JSON.stringify(newItems));
        toast.info('Item has been removed', {});
    }

    function resetCart() {
        setItems([]);
        localStorage.setItem("cart", JSON.stringify([]));
    }

    function completeBooking() {
        axios.post("/orders", {
            "user": localStorage.getItem("user-id"),
            "items": items,
            "comments": comments
        }).then((response) => {
                console.log('order response = ' + JSON.stringify(response.data));
                setOrder(response.data);
                resetCart();
                setStep(3);
            }).catch(() => {
                toast.error('Ooops! Something wrong happened. We are looking into it.', { position: "top-center" });
            });

    }

    return (
        <CampLayout page="book" cart={items} step={step} setStep={setStep}>

            <div className="px-7 pb-7 w-full">
                {kids.length === 0 ?
                    <p className="text-base">Before you can start booking you need to add at least one kid. Use <a href="/manage" className="underline text-blue-600 hover:text-blue-800">Manage kids</a> page for this.</p>
                    :
                    <>
                        <div style={{ paddingBottom: 10 }} hidden={step !== 1}>
                            <p className="mb-2 text-base">Select kids you are booking the camp(s) for</p>
                            <CheckboxGroup value={selectedKids} onChange={(e) => setKidsForBooking(e)}>
                                {kids.map((kid) => 
                                    <Checkbox key={kid._id} value={kid._id}>{kid.firstName} {kid.lastName}</Checkbox>
                                )}                                        
                            </CheckboxGroup>
                            {/* <CheckPicker
                                    data={kids}
                                    searchable={false}
                                    style={{ paddingLeft: 7 }}
                                    value={selectedKids}
                                    onChange={setSelectedKids}
                                /> */}
                        </div>
                        {step === 1 &&
                            <div >
                                <p className="mb-3 text-base">Time to find the right camp for your kids.</p>
                                <Accordion onChange={(uuids) => updateWeeks(uuids)} allowZeroExpanded={true}>
                                    {camps.map((camp) =>
                                        <AccordionItem key={camp._id + "-" + camp.theme} uuid={camp._id + "-" + camp.theme}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {camp.theme ? camp.theme + ' (' + camp.name + ')' : camp.name}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="w-full flex-row overflow-auto hidden lg:flex">
                                                    <div className="flex flex-col w-64 min-w-max border-r gap-3">
                                                        {locations.length > 0 ?
                                                            locations.map((location) =>
                                                                <div className="mr-5" key={location._id}>
                                                                    <p className={"py-2 border-b-2 cursor-pointer " + (locationId === location._id ? "text-blue-500 border-blue-500 " : "hover:border-blue-300")}
                                                                        onClick={() => setLocationId(location._id)}>{location.name} ({location.address})</p>
                                                                </div>
                                                            )
                                                            : <p>We do not have open slots for this camp currently. Try another one.</p>
                                                        }
                                                    </div>
                                                    {locationId ?
                                                        <div className="ml-5 transition duration-1000 flex-grow-0 flex-shrink-0">
                                                            <div className="grid-cols-3 gap-x-7 gap-y-3 items-center grid">
                                                                {weeks.filter(week => week.location._id === locationId).map(week =>
                                                                    <React.Fragment key={week._id}>
                                                                        <span>
                                                                            {moment(week.startDate).format("DD/MM")} - {moment(week.endDate).format("DD/MM")}
                                                                        </span>
                                                                        {/* <div className="flex"> */}
                                                                            <select disabled={camp.options.length <= 1} onChange={(event) => changeDayOption(event, week)}>
                                                                                {camp.options.map((dayOption) =>
                                                                                    <option key={dayOption.name} value={dayOption.name}>{dayOption.name} - ${dayOption.price} / child</option>
                                                                                )}
                                                                            </select>
                                                                            {/* <div style={{display: "flex", justifyContent: "center"}}> */}
                                                                            {items.filter(item => item.week._id === week._id).length === 0 ? // not already added?
                                                                                <div className="ml-4 cursor-pointer hover:bg-blue-300 flex">
                                                                                  <MdAddShoppingCart size="1.4em" onClick={() => addToCart(week)} hidden={selectedKids.length === 0}/>
                                                                                  <p onClick={() => addToCart(week)} hidden={selectedKids.length === 0}><b>Reserve for {selectedKids.length} {selectedKids.length > 1 ? "kids" : "kid"}</b></p>
                                                                                </div>
                                                                                :
                                                                                <div className="ml-4 cursor-pointer hover:bg-red-300 flex">
                                                                                  <MdShoppingCart size="1.4em" onClick={() => deleteFromCart(week._id)} />
                                                                                  <p onClick={() => deleteFromCart(week._id)}>Delete from cart</p>
                                                                                </div>
                                                                            }
                                                                            {/* </div> */}
                                                                        {/* </div> */}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="lg:hidden">
                                                    {locations.length > 0 ?
                                                        locations.map((location) =>  
                                                            <Panel key={location._id} header={`${location.name} (${location.address})`} collapsible bordered>
                                                                <div className="transition duration-1000 flex-grow-0 flex-shrink-0">
                                                                    <div className="grid-cols-acccordion gap-x-7 gap-y-3 items-center grid sm:grid-cols-acccordion-lg">
                                                                        {weeks.filter(week => week.location._id === location._id).map(week =>
                                                                            <React.Fragment key={week._id}>
                                                                                <div className="flex flex-col">
                                                                                    <span>
                                                                                        {moment(week.startDate).format("DD/MM")} - {moment(week.endDate).format("DD/MM")} 
                                                                                    </span>
                                                                                    <select className="w-44 mt-2" disabled={camp.options.length <= 1} onChange={(event) => changeDayOption(event, week)}>
                                                                                        {camp.options.map((dayOption) =>
                                                                                            <option key={dayOption.name} value={dayOption.name}>{dayOption.name} - ${dayOption.price} / child</option>
                                                                                        )}
                                                                                    </select>
                                                                                </div>
                                                                                {items.filter(item => item.week._id === week._id).length === 0 ? // not already added?
                                                                                    <div className="cursor-pointer hover:bg-blue-300 flex">
                                                                                        <MdAddShoppingCart size="1.4em" onClick={() => addToCart(week)} hidden={selectedKids.length === 0}/>
                                                                                        <p className="hidden sm:block" onClick={() => addToCart(week)} hidden={selectedKids.length === 0}><b>Reserve for {selectedKids.length} {selectedKids.length > 1 ? "kids" : "kid"}</b></p>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="cursor-pointer hover:bg-red-300 flex">
                                                                                        <MdShoppingCart size="1.4em" onClick={() => deleteFromCart(week._id)} />
                                                                                        <p className="hidden sm:block" onClick={() => deleteFromCart(week._id)}>Delete from cart</p>
                                                                                    </div>
                                                                                }
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        )
                                                        : <p>We do not have open slots for this camp currently. Try another one.</p>
                                                    }
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    )
                                    }
                                </Accordion>

                                <FlexboxGrid justify="end" className="pt-6">
                                     <Button
                                        // block
                                        appearance="primary"
                                        onClick={items.length === 0 ? null : () => setStep(2)}
                                        disabled={items.length === 0}
                                        size="md"
                                    >
                                        Proceed to checkout
                                    </Button>

                                </FlexboxGrid>

                            </div>
                        }
                        {step === 2 &&
                            <>
                                <p className="mb-3 text-base">Order details:</p>
                                <div className="shadow overflow-auto border border-gray-200 w-full rounded-lg hidden lg:block">
                                    <table className="table-fixed w-full">
                                        <thead>
                                            <tr className="border-b">
                                                <th className="w-2/12 text-left py-3 pl-5">Kid</th>
                                                <th className="w-3/12 text-left py-3">Camp</th>
                                                <th className="w-2/12 text-left">Location</th>
                                                {/* <th className="w-1/12 text-left">Bus</th> */}
                                                <th className="w-2/12 text-left">Dates</th>
                                                <th className="w-1/12 text-left">Option</th>
                                                <th className="w-1/12 text-left">Price</th>
                                                <th className="w-1/12 text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {items.map(item =>
                                                <tr key={item.week._id + item.kid._id}>
                                                    <td className="py-5 pl-5">{item.kid.firstName + " " + item.kid.lastName}</td>
                                                    <td>{item.week.theme ? item.week.theme + ' (' + item.week.camp.name + ')' : item.week.camp.name}</td>
                                                    <td>{item.week.location.name} ({item.week.location.address})</td>
                                                    {/* <td><input type="checkbox" /></td> */}
                                                    <td>{moment(item.week.startDate).format("DD/MM/YYYY")} - {moment(item.week.endDate).format("DD/MM/YYYY")}</td>
                                                    <td>{item.dayOption}</td>
                                                    <td>${item.price}</td>
                                                    <td className="cursor-pointer text-red-500 text-sm" onClick={() => deleteFromCart(item.week._id, item.kid._id)}>Delete</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="overflow-auto w-full block lg:hidden">
                                    {items.map(item =>
                                        <ul key={item.week._id + item.kid._id} className="shadow border border-gray-200 w-full rounded-lg p-3 relative">
                                            <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block pb-1'>Kid</div>
                                                <div className='custom-overflow-ellipsis'>{item.kid.firstName + " " + item.kid.lastName}</div>
                                            </li>
                                            <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block pb-1'>Camp</div>
                                                <div className='custom-overflow-ellipsis'>{item.week.theme ? item.week.theme + ' (' + item.week.camp.name + ')' : item.week.camp.name}</div>
                                            </li>
                                            <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block pb-1'>Location</div>
                                                <div className='custom-overflow-ellipsis'>{item.week.location.name} ({item.week.location.address})</div>
                                            </li>
                                            {/* <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block pb-1'>Bus</div>
                                                <div className='custom-overflow-ellipsis'><input type="checkbox" /></div>
                                            </li> */}
                                            <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block pb-1'>Dates</div>
                                                <div className='custom-overflow-ellipsis'>{moment(item.week.startDate).format("DD/MM/YYYY")} - {moment(item.week.endDate).format("DD/MM/YYYY")}</div>
                                            </li>
                                            <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block pb-1'>Option</div>
                                                <div className='custom-overflow-ellipsis'>{item.dayOption}</div>
                                            </li>
                                            <li className="flex flex-row">
                                                <div className='w-20 font-bold inline-block'>Price</div>
                                                <div className='custom-overflow-ellipsis'>${item.price}</div>
                                            </li>
                                            <li className="flex flex-row">

                                                <IconButton
                                                    onClick={() => deleteFromCart(item.week._id, item.kid._id)}
                                                    className="delete-mobile-button z-20 right-0 top-0 absolute"
                                                    icon={<CloseIcon size="2em" color="#ef4444"/>}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <div className="flex justify-start my-7">
                                    <p className="text-base">Full price for your booking: <b>${items.reduce((total, item) => total + Number(item.price), 0)} SGD</b><br/>
                                    We will apply all eligible discounts and email you the invoice.</p>
                                </div>

                                <p className="mb-3 text-base">Let us know if you have special requests:</p>

                                <Input
                                    as="textarea"
                                    rows={5}
                                    placeholder="E.g. friends you want to be grouped with"
                                    value={comments}
                                    onChange={setComments}
                                    className="w-full max-w-500"
                                    style={{ paddingTop: 10 }}
                                />

                                <FlexboxGrid justify="start" style={{ paddingTop: 20 }}>
                                    <Button
                                        appearance="default"
                                        onClick={() => setStep(1)}
                                        size="lg"
                                        style={{ marginRight: 15 }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        appearance="primary"
                                        onClick={items.length === 0 ? null : () => completeBooking()}
                                        size="lg"
                                        disabled={items.length === 0}
                                    >
                                        Confirm
                                    </Button>
                                </FlexboxGrid>

                                {/* <div className="flex justify-start my-7">
                                    <p className="text-xs">*Our friendly staff will review your order and send you the invoice with the final amount</p>
                                </div> */}

                            </>
                        }
                        {step === 3 &&
                            <>
                            <p className="text-3xl"><b>Thank you for your registration request!</b></p><br/>
                            
                            <p className="text-lg">Booking Number: <b>{order.orderNumber}</b></p>
                            <br/>
                            <p className="text-base">Our team is now reviewing your request and will get in touch soon.</p>
                            {/* <p className="text-base">In the meantime please find below the banking details you can use for the payment.</p>
                            <br/>
                            <p className="text-base">Bank: <b>DBS</b><br/>
                            Account number: <b>123-123-1</b><br/>
                            Amount: <b>${order.items.reduce((total, item) => total + Number(item.price), 0)}</b><br/>
                            Reference: <b>{order.orderNumber}</b></p> */}
                            <br/><br/>
                            <p className="text-base">For any urgent matters please whatsapp us at <a href="https://wa.me/6589501968" target="_blank">+65 8950 1968</a></p>
                            <p className="text-base">Alternatively, email us at: <a href="mailto:info@newtonshow.com">info@newtonshow.com</a></p>
                            </>
                        }
                    </>

                }

            </div>
        </CampLayout>
    )
}

export default Book;
