import React from 'react';
import { MdShoppingCart } from 'react-icons/md'
import { IoLogoWhatsapp } from 'react-icons/io';
import { FlexboxGrid, Badge, IconButton } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@rsuite/icons/Menu';

const CampTopbar = ({ cart, setIsMenu }) => {

  const navigate = useNavigate();

  return (
    <FlexboxGrid justify="space-between" align="middle" className="bg-blue-tech-hexagon bg-cover" style={{borderBottom: '1px solid #DCDCDC'}}>
      <IconButton onClick={() => setIsMenu(true)} className="mobile-menu-button ml-6" icon={<MenuIcon size="2em" />} />
      <img src="/logo.webp" alt="Newton Show" style={{ width: '150px', marginTop: 10, marginBottom: 10, marginLeft:20 }} />
      <div className="flex flex-row items-center gap-3">
        <a className="hover:no-underline flex flex-row items-center gap-1 phone-number" href="https://wa.me/6589501968" target="_blank">
          <IoLogoWhatsapp size="2em" color="#25d366"/>
          <span className="hidden lg:block">+65 8950 1968</span>
          </a>
        <Badge content={cart ? cart.length : ''} className="my-2.5 mr-6 lg:mr-10">
          {cart && cart.length > 0 ? 
          <MdShoppingCart size="2em" onClick={() => navigate('/book', { state: { step: 2 } })} className="cursor-pointer"/> :
          <MdShoppingCart size="2em"/>
        }
        </Badge>
      </div>
    </FlexboxGrid>

  )
}

export default CampTopbar;