import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Schema, Button } from 'rsuite';

import { checkIfLoggedIn } from '../services/utilService';
import CampLayout from './common/CampLayout'
import { Field } from './common/Field'

const model = Schema.Model({
    password: Schema.Types.StringType().isRequired('This field is required.'),
    newPassword: Schema.Types.StringType().isRequired('This field is required.'),
    confirmedNewPassword: Schema.Types.StringType()
        .addRule((value, data) => {
            if (value !== data.newPassword) {
                return false;
            }

            return true;
        }, 'Passwords do not match')
        .isRequired('This field is required.')
});

const ChangePassword = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        checkIfLoggedIn();

        if (!localStorage.getItem("user-id")) {
            navigate("/login");
        }
    }, []);

    const [formValue, setFormValue] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmedNewPassword: ''
    });

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }
    };

    const formRef = React.useRef();

    return (
        <CampLayout page="password" cart={JSON.parse(localStorage.getItem("cart"))}>
            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                model={model}
                layout="horizontal">

                <Field
                    name="password"
                    label="Old password"
                    type="password"
                    style={{ width: 250 }}
                />
                <Field
                    name="newPassword"
                    label="New password"
                    type="password"
                    style={{ width: 250 }}
                />
                <Field
                    name="confirmedNewPassword"
                    label="Confirm new password"
                    type="password"
                    style={{ width: 250 }}
                />

                <Field
                    name="saveButton"
                    accepter={Button}
                    appearance="primary"
                    onClick={handleSubmit}
                >
                    Save Changes
                </Field>

            </Form>
        </CampLayout>
    )
}

export default ChangePassword;